<template>
  <div>
    <v-row v-if='loading' class='mt-5'>
      <v-col cols='12'>
        <div align='center'>
          <span class='font-weight-semibold primary--text'>
            <div class='loader'>{{ $t('data_loading') }}</div>
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col v-if='block1.length > 0' class='pt-0' cols='12 ' md='6' lg='3'>
        <v-card>
          <v-list nav>
            <v-list-item class='my-0'>
              <v-list-item-title class='font-weight-semibold text-lg'>
                {{ $t('SaleReport') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class='mt-0'></v-divider>
            <v-list-item-group v-model.trim='block1Selection' color='primary'>
              <v-list-item v-for='item in block1' :key='item.path' :to='{ path: item.path }'>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col v-if='block2.length > 0' cols='12' md='6' lg='3' class='pt-0'>
        <v-card>
          <v-list nav>
            <v-list-item class='my-0'>
              <v-list-item-title class='font-weight-semibold'>
                {{ $t('ReportAccounting') }}
              </v-list-item-title>
            </v-list-item>
            <v-divider class='mt-0'></v-divider>

            <v-list-item-group v-model.trim='block2Selection' color='primary'>
              <v-list-item v-for='(item, index) in block2' :key='index' :to='item.path'>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useReport from '../reports/useReport'
import useReportBranch from './useReportBanch'
import store from '@/store/app'

export default {
  setup() {
    return {
      ...useReportBranch(),
    }
  },
}
</script>

<style lang='scss'>
@import '~vuetify/src/styles/styles.sass';

.loader,
.loader:before,
.loader:after {
  background: var(--v-primary-base);
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: var(--v-primary-base);
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
</style>
