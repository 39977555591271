import menu from '@/api/menu'
import { ref, watch, onMounted } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'
import { LLayerGroup } from 'vue2-leaflet'

export default function useReportBranch() {
  const block1 = ref([])
  const block2 = ref([])
  const block1Selection = ref('')
  const block2Selection = ref('')
  const loading = ref(true)
  onMounted(() => {
    loading.value = true
    menu()
      .then(res => {
        const menuList = res.find(item => item.group_menu_id == '17')
        menuList.menu.forEach(element => {
          if (['reportcourse2', 'reportdrug2', 'reportserve2', 'reportcustomer2', 'salessummary2'].includes(element.menu_link)) {
            block1.value.push({
              path: `/${element.menu_link}`,
              name: i18n.locale == 'th' ? element.menu_th : element.menu_en,
            })
          }
          if (['payoutreport2', 'Incomereport2', 'paymentreport2'].includes(element.menu_link)) {
            block2.value.push({
              path: `/${element.menu_link}`,
              name: i18n.locale == 'th' ? element.menu_th : element.menu_en,
            })
          }
        })
        loading.value = false
      })
      .catch(error => {
        console.log('error : ', error)
        loading.value = false
      })
  })

  return {
    block1Selection,
    block2Selection,
    block1,
    block2,
    loading,
  }
}
